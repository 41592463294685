const config = {
  surveyTitle: "TikTok Ad Test",
  surveyNamePlaceholder: "eg: TikTok Video Ads Test",
  videoSettingsNextRoute: "vid-tiktok-creative",
  creativeUploadPevRoute: "vid-tiktok-setting",
  creativeUploadNextRoute: "vid-tiktok-brand-strategy",
  personaNextRoute: "vid-tiktok-key-metrics",
  personaPrevRoute: "vid-tiktok-creative",
  keyMetricsNextRoute: "vid-tiktok-launch",
  keyMetricsPrevRoute: "vid-tiktok-brand-strategy",
  launchPrevRoute: "vid-tiktok-key-metrics",
  brandLogoUpload: true,
  progressBarSteps: [
    {
      title: "General Settings",
      subItems: ["General Study information"],
      icon: {
        name: "IconAdjustments",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 1,
      route: "vid-tiktok-setting",
    },
    {
      title: "Creative",
      subItems: ["Creative Submission Details"],
      icon: {
        name: "IconCreative",
        width: "32",
        height: "33",
        viewBox1: "22",
        viewBox2: "23",
        color: "#4318FF",
      },
      index: 2,
      route: "vid-tiktok-creative",
    },
    {
      title: "Brand Strategy",
      subItems: ["Key emotional attributes"],
      icon: {
        name: "IconPie",
        width: "32",
        height: "33",
        viewBox1: "22",
        viewBox2: "23",
        color: "transparent",
      },
      index: 3,
      route: "vid-tiktok-brand-strategy",
    },
    {
      title: "Key Metrics",
      subItems: ["Brand Analysis"],
      icon: {
        name: "IconMetrics",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 4,
      route: "vid-tiktok-key-metrics",
    },
    {
      title: "Launch",
      subItems: ["Summary & Confirmation"],
      icon: {
        name: "IconRocket",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 6,
      route: "vid-tiktok-launch",
    },
  ],
  insertSurveyStimWS: "InsertSurveyStimYoutube",
};

export default config;
