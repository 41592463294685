import Vue from "vue";

export const store = Vue.observable({
  surveyPrice: {
    SurveyId: 1234,
    SurveyType: 8,
    ClickSpex: true,
    Stims: [{ duration: 100 }],
    Question: [],
    Audience: {
      sampleSize: 200,
      categoryUser: 0,
      genPop: 1,
    },
  },
  surveyTotalPrice: 0,
  projectSummary: {
    projectName: null,
    sampleSize: null,
    country: null,
    audience: null,
    language: null,
  },
  clickspexReport: {
    comments: [],
    filteredComments: [],
    imageData: "",
  },
  userInfo: null,
  surveyPackTestPrice: 0,
  checkingFeasibilityLoader: [],
  feasibilitySuccess: null,
  priceLoader: [],
  csvZoneLoader: [],
  defaultTimePeriod: null,
  navClosed: false,
  defaultSurveyType: null,
  helpModal: {
    open: false,
    tabName: '',
  },
});

export const mutations = {
  setSurveyPrice(data, property) {
    if (Array.isArray(store.surveyPrice[property])) {
      store.surveyPrice[property].push(data);
    } else {
      store.surveyPrice[property] = data;
    }
  },
  setSurveyPriceQuestion(data) {
    store.surveyPrice.Question = data;
  },
  setSurveyPriceStims(data) {
    store.surveyPrice.Stims = data;
  },
  setSurveyPriceCategoryUser(data) {
    store.surveyPrice.Audience.categoryUser = data;
  },
  setSurveyPriceCategoryGenPop(data) {
    store.surveyPrice.Audience.genPop = data;
  },
  setSurveyPriceCategorySampleSize(data) {
    store.surveyPrice.Audience.sampleSize = data;
  },
  setProjectSummary(data, property) {
    store.projectSummary[property] = data;
  },
  setClickspexReportComments(data) {
    let attrs = data.map((attr) => {
      return {
        xPos: attr.xPos,
        yPos: attr.yPos,
        spexID: attr.spexID,
        iconID: attr.iconID,
        clickOrder: attr.clickOrder,
        comment: attr.comment,
        translation: attr.translation,
        isActive: false,
        age: parseInt(attr.Age),
        gender: attr.Gender,
      };
    });
    store.clickspexReport.comments = attrs;
    store.clickspexReport.filteredComments = attrs;
  },
  resetClickspexFilters() {
    store.clickspexReport.filteredComments = store.clickspexReport.comments;
  },
  setClickspexReportImage(data) {
    store.clickspexReport.imageData = data;
  },
  setActiveComment(data) {
    store.clickspexReport.filteredComments[data.index].isActive = data.isActive;
  },
  commentsLikeDislikeFilter(data) {
    if (data.iconID === "Clicks with comment") {
      if (data.clickOrder !== "All clicks") {
        store.clickspexReport.filteredComments = store.clickspexReport.comments
          .filter((item) => item.comment !== "")
          .filter((item) => item.clickOrder == data.clickOrder)
          .filter(({ gender }) => {
            return data.gender.includes(gender);
          })
          .filter(({ age }) =>
            data.yrs.some(([low, high]) => age >= low && age <= high)
          );
      } else {
        store.clickspexReport.filteredComments = store.clickspexReport.comments
          .filter((item) => item.comment !== "")
          .filter(({ gender }) => {
            return data.gender.includes(gender);
          })
          .filter(({ age }) =>
            data.yrs.some(([low, high]) => age >= low && age <= high)
          );
      }
    } else {
      if (data.iconID !== "All clicks") {
        if (data.clickOrder !== "All clicks") {
          store.clickspexReport.filteredComments =
            store.clickspexReport.comments
              .filter((item) => item.iconID == data.iconID)
              .filter((item) => item.clickOrder == data.clickOrder)
              .filter(({ gender }) => {
                return data.gender.includes(gender);
              })
              .filter(({ age }) =>
                data.yrs.some(([low, high]) => age >= low && age <= high)
              );
        } else {
          store.clickspexReport.filteredComments =
            store.clickspexReport.comments
              .filter((item) => item.iconID == data.iconID)
              .filter(({ gender }) => {
                return data.gender.includes(gender);
              })
              .filter(({ age }) =>
                data.yrs.some(([low, high]) => age >= low && age <= high)
              );
        }
      } else if (data.clickOrder !== "All clicks") {
        store.clickspexReport.filteredComments = store.clickspexReport.comments
          .filter((item) => item.clickOrder == data.clickOrder)
          .filter(({ gender }) => {
            return data.gender.includes(gender);
          })
          .filter(({ age }) =>
            data.yrs.some(([low, high]) => age >= low && age <= high)
          );
      } else {
        store.clickspexReport.filteredComments = store.clickspexReport.comments
          .filter(({ gender }) => {
            return data.gender.includes(gender);
          })
          .filter(({ age }) =>
            data.yrs.some(([low, high]) => age >= low && age <= high)
          );
      }
    }
  },
  setUserData(data) {
    store.userInfo = data;
  },
  setSurveyTotalPrice(data) {
    store.surveyTotalPrice = data;
  },
  setPackTestTotalPrice(data) {
    store.surveyPackTestPrice = data;
  },
  setCheckingFeasibilityLoader(data) {
    if (store.checkingFeasibilityLoader.includes(data)) {
      store.checkingFeasibilityLoader.splice(
        store.checkingFeasibilityLoader.indexOf(data),
        1
      );
    } else {
      store.checkingFeasibilityLoader.push(data);
    }
  },
  setPriceLoader(data) {
    if (store.priceLoader.includes(data)) {
      store.priceLoader.splice(store.priceLoader.indexOf(data), 1);
    } else {
      store.priceLoader.push(data);
    }
  },
  setCSVZoneLoader(data) {
    if (store.csvZoneLoader.includes(data)) {
      store.csvZoneLoader.splice(store.csvZoneLoader.indexOf(data), 1);
    } else {
      store.csvZoneLoader.push(data);
    }
  },
  setFeasibilitySuccess(data) {
    store.feasibilitySuccess = data;
  },
  setDefaultTimePeriod(data) {
    store.defaultTimePeriod = data;
  },
  setNavClosed(data) {
    store.navClosed = data;
  },
  setDefaultSurveyType(data) {
    store.defaultSurveyType = data;
  },
  openHelpModal(open, tabName) {
    store.helpModal.open = open;
    store.helpModal.tabName = tabName;
  },
};
