import axios from "axios";
import * as jsUtils from "@/utils/jsUtils.js";

let apiUrl;
if (
  window.location.hostname === "app.hotspexlabs.com" ||
  window.location.hostname === "pg.hotspexlabs.com" ||
  window.location.hostname === "d3n7xxuq0fwznj.cloudfront.net" ||
  window.location.hostname === "d30wz03irsnsp8.cloudfront.net" ||
  window.location.hostname === "d11qiq41szmpd8.cloudfront.net"
) {
  apiUrl = "https://admin.hotspexlabs.com";
} else if (window.location.hostname === "app-uat.hotspexstudio.com") {
  apiUrl = "https://admin-uat.hotspexstudio.com";
} else {
  apiUrl = "https://staging-admin.hotspexstudio.com";
  //apiUrl = "https://admin-uat.hotspexstudio.com";
}
let wsStudioHeader = `${apiUrl}/api/WebService/`;
let asynLaunch = `${apiUrl}/SurveyAsynchronous/LaunchSurvey`;
let packTestAsynLaunch = `${apiUrl}/packTestAsync/LaunchSurvey`;
let checkout = `${apiUrl}/Cart/create-checkout-session`;

let apiStudio = axios.create({
  baseURL: wsStudioHeader,
  // baseURL: "https://jwt-admin.hotspexstudio.com/api/WebService/",
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
});


let chatBotApi = axios.create({
  baseURL: "https://func-dynamicplot-api.azurewebsites.net/api/",
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
});

apiStudio.interceptors.request.use(
  (config) => {
    const token = jsUtils.getLocalAccessToken();
    const refreshToken = jsUtils.getLocalRefreshToken();
    // const token = null;
    // const refreshToken = null;
    let cookieData;

    if (!token || !refreshToken) {
      const cookieValue = jsUtils.readCookie("HS-STUDIO");
      if (cookieValue) {
        try {
          cookieData = JSON.parse(cookieValue);
          if (typeof cookieData !== "object" || cookieData === null) {
            throw new Error("Invalid JSON data");
          }
        } catch (error) {
          console.log("Error parsing JSON data: " + error);
        }
      }
    }
    if (
      (refreshToken || (cookieData && cookieData.RefreshToken)) &&
      config.url === "GetNewAccessToken"
    ) {
      config.headers["Authorization"] =
        "Bearer " + (refreshToken || (cookieData && cookieData.RefreshToken));
    } else if (token || (cookieData && cookieData.AccessToken)) {
      config.headers["Authorization"] =
        "Bearer " + (token || (cookieData && cookieData.AccessToken));
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiStudio.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "login" && err.response) {
      // Access Token was expired
      if (err.response.status === 408 && !originalConfig._retry) {
        console.log("408 error");
        originalConfig._retry = true;

        try {
          const rs = await apiStudio.post("GetNewAccessToken", null);
          jsUtils.updateLocalAccessToken(rs.data.AccessToken);

          return apiStudio(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export function UserLogin(callback, userObj) {
  apiStudio.post("UserLogin", userObj).then(
    (response) => {
      //console.log("UserLogin: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UserLogin fail: " + error);
    }
  );
}

// export function UserLogin(callback, userObj) {
//   apiStudio
//     .post(`UserLogin?email=${userObj.email}&password=${userObj.password}`)
//     .then(
//       (response) => {
//         // console.log("UserLogin: == ", JSON.stringify(response.data));
//         if (callback) {
//           callback(response.data);
//         }
//       },
//       (error) => {
//         console.log("UserLogin fail: " + error);
//       }
//     );
// }

export function ForgotPassword(callback, userObj) {
  apiStudio.post("ForgotPassword", userObj).then(
    (response) => {
      // console.log("ForgotPassword: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("ForgotPassword fail: " + error);
    }
  );
}

export function UpdatePassword(callback, userObj) {
  apiStudio.post("UpdatePassword", userObj).then(
    (response) => {
      // console.log("UpdatePassword: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UpdatePassword fail: " + error);
    }
  );
}

export function RegisterUser(callback, userObj) {
  apiStudio.post("RegisterUser", JSON.stringify(userObj)).then(
    (response) => {
      // console.log("RegisterUser: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("RegisterUser fail: " + error);
    }
  );
}

export function VerifyEmail(callback, userObj) {
  apiStudio.post("VerifyEmail", userObj).then(
    (response) => {
      // console.log("VerifyEmail: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("VerifyEmail fail: " + error);
    }
  );
}

export function MarketList(callback) {
  apiStudio.get("MarketList", { data: null }).then(
    (response) => {
      // console.log("MarketList: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("MarketList fail: " + error);
    }
  );
}

export function GetMyTestFilter(ClientId, callback) {
  apiStudio.post("MyTestFilter", JSON.stringify({ ClientId: ClientId })).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("MyTestFilter fail: " + error);
    }
  );
}

// Get Project List based on clientID
export function GetMyTest(clientObj, callback) {
  apiStudio.post("GetMyTest", JSON.stringify(clientObj)).then(
    (response) => {
      // console.log("GetMyTest: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetMyTest fail: " + error);
    }
  );
}

// Get Project List based on clientID
export function GetProject(data, callback) {
  apiStudio.post("GetProject", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetProject fail: " + error);
    }
  );
}

export function ProjectServices(project, callback) {
  apiStudio.post(project.endpoint, JSON.stringify(project.data)).then(
    (response) => {
      console.log("ProjectServices: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("ProjectServices fail: " + error);
    }
  );
}

export function GetCategories(userid, callback) {
  apiStudio.post("GetCategories", userid).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetCategories fail: " + error);
    }
  );
}

export function GenerateFramesFromVideo(url, callback) {
  apiStudio.post("GenerateFramesFromVideo", JSON.stringify({ url: url })).then(
    (response) => {
      // console.log("GetCategories: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetCategories fail: " + error);
    }
  );
}

// save video survey setting date
export function InsertSurveyData1(model, callback) {
  apiStudio.post("InsertSurveyData1", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("InsertSurveyData1 fail: " + error);
    }
  );
}

// update video survey setting date
export function UpdateSurveyData1(model, callback) {
  apiStudio.post("UpdateSurveyData1", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UpdateSurveyData1 fail: " + error);
    }
  );
}

// DELETE STIM
export function DeleteSurveyStim(StimId, callback) {
  apiStudio.post("DeleteSurveyStim", JSON.stringify({ StimId: StimId })).then(
    (response) => {
      console.log("DeleteSurveyStim: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("DeleteSurveyStim fail: " + error);
    }
  );
}

// SAVE/UPDATE STIM
export function InsertSurveyStim(stimObj, callback) {
  apiStudio.post("InsertSurveyStim", JSON.stringify(stimObj)).then(
    (response) => {
      console.log("InsertSurveyStim: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("InsertSurveyStim fail: " + error);
    }
  );
}

export function InsertSurveyStimYoutube(stimObj, callback) {
  apiStudio.post("InsertSurveyStimYoutube", JSON.stringify(stimObj)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("InsertSurveyStimYoutube fail: " + error);
    }
  );
}

// GET PERSONA ATTRIBUTES LIST
export function getPersonaAttributes(callback) {
  apiStudio.get("getPersonaAttributes", { data: null }).then(
    (response) => {
      // console.log("getPersonaAttributes: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("getPersonaAttributes fail: " + error);
    }
  );
}

export function SurveyTypeFilter(data, callback) {
  apiStudio
    .post("SurveyTypeFilter", JSON.stringify({ accountId: data.accountId }))
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("SurveyTypeFilter fail: " + error);
      }
    );
}

// SAVE PERSONA ATTRIBUTES
export function SavePersonaAttributes(personaObj, callback) {
  apiStudio.post("SavePersonaAttributes", JSON.stringify(personaObj)).then(
    (response) => {
      // console.log("SavePersonaAttributes: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("SavePersonaAttributes fail: " + error);
    }
  );
}

// SAVE PERSONA ATTRIBUTES
export function SaveStandardSurveyQuestions(questionObj, callback) {
  apiStudio
    .post("SaveStandardSurveyQuestions", JSON.stringify(questionObj))
    .then(
      (response) => {
        // console.log("SaveStandardSurveyQuestions: == ", JSON.stringify(response.data));
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("SaveStandardSurveyQuestions fail: " + error);
      }
    );
}

// LAUNCH SURVEY
export function LaunchSurvey(data, callback) {
  apiStudio.post(asynLaunch, JSON.stringify(data)).then(
    (response) => {
      console.log("LaunchSurvey: == ", JSON.stringify(response));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("LaunchSurvey fail: " + error);
    }
  );
}

export function PackTestLaunchSurvey(data, callback) {
  apiStudio.post(packTestAsynLaunch, JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("LaunchSurvey fail: " + error);
    }
  );
}

// PAY & LAUNCH SURVEY
export function PayLaunchSurvey(data, callback) {
  apiStudio
    .post(
      checkout,
      JSON.stringify({ SurveyId: data.surveyID, Price: data.price })
    )
    .then(
      (response) => {
        console.log("PayLaunchSurvey: == ", JSON.stringify(response));
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("PayLaunchSurvey fail: " + error);
      }
    );
}

// GET SURVEY PAYMENT STATUS
export function GetSurveyPaymetStatus(data, callback) {
  apiStudio
    .post("GetSurveyPaymetStatus", JSON.stringify({ SurveyId: data }))
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("GetSurveyPaymetStatus fail: " + error);
      }
    );
}

// SAVE AUDIENCE
export function InsertAudienceDetails(audientObj, callback) {
  apiStudio.post("InsertAudienceDetails", JSON.stringify(audientObj)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("InsertAudienceDetails fail: " + error);
    }
  );
}

export function GetCombineSurveyDetails(surveyObj, callback) {
  apiStudio
    .get("GetCombineSurveyDetails", {
      params: {
        SurveyID: surveyObj.SurveyID,
        SurveyTypeID: surveyObj.SurveyTypeID,
      },
      responseType: "json",
    })
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("GetCombineSurveyDetails fail: " + error);
      }
    );
}

export function GetPackTestSurveyDetails(surveyObj, callback) {
  apiStudio
    .get("GetPackTestSurveyDetails", {
      params: {
        SurveyID: surveyObj.SurveyID,
        SurveyTypeID: surveyObj.SurveyTypeID,
      },
      responseType: "json",
    })
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("GetPackTestSurveyDetails fail: " + error);
      }
    );
}

export function surveyServices(survey, callback) {
  apiStudio.post(survey.endpoint, JSON.stringify(survey.data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("surveyServices fail: " + error);
    }
  );
}

export function InsertCustomQuestion(data, callback) {
  apiStudio.post("InsertCustomQuestion", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("InsertCustomQuestion fail: " + error);
    }
  );
}

export function GetCustomQuestion(id, callback) {
  apiStudio
    .get("GetCustomQuestionsOptions", {
      params: {
        SurveyID: id,
      },
    })
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("GetCustomQuestions fail: " + error);
      }
    );
}

export function GetSurveyPrice(model, callback) {
  apiStudio.post("GettingSurveyPrice", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GettingSurveyPrice fail: " + error);
    }
  );
}

export function GetPackTestPrice(model, callback) {
  apiStudio.post("GetPackTestSurveyPrice", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetPackTestPrice fail: " + error);
    }
  );
}

export function GetPackTestFinalPrice(model, callback) {
  apiStudio
    .post("GetPackTestFinalSurveyPrice", JSON.stringify({ surveyId: model }))
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("GetPackTestFinalPrice fail: " + error);
      }
    );
}

export function GetPackFeasibility(model, callback) {
  apiStudio.post("PackFeasibilityCheck", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("PackFeasibilityCheck fail: " + error);
    }
  );
}

export function GetFinalSurveyPrice(SurveyId, callback) {
  apiStudio.post("GettingFinalSurveyPrice", JSON.stringify({ SurveyId })).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GettingFinalSurveyPrice fail: " + error);
    }
  );
}

export function setErrorLog(model, callback) {
  apiStudio.post("ErrorLog", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("setErrorLog fail: " + error);
    }
  );
}

// GET USER INFO
export function GetUserInfo(data, callback) {
  apiStudio.post("Getusers", JSON.stringify({ userid: data })).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("Getusers fail: " + error);
    }
  );
}

export function UpdateUserData(model, callback) {
  apiStudio.post("UpdateUsersDataNew", JSON.stringify(model)).then(
    (response) => {
      console.log("UpdateUsersDataNew: == ", JSON.stringify(response.data));
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UpdateUsersDataNew fail: " + error);
    }
  );
}

export function BrandSearch(model, callback) {
  apiStudio.post("BrandSearch", JSON.stringify({ val: model })).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("BrandSearch fail: " + error);
    }
  );
}

export function CreateUser(model, callback) {
  apiStudio.post("CreateUser", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("CreateUser fail: " + error);
    }
  );
}

export function GetAccountUsers(model, callback) {
  apiStudio.post("GetAccountUsers", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetAccountUsers fail: " + error);
    }
  );
}

export function GetAccountClient(model, callback) {
  apiStudio.post("GetAccountClient", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetAccountClient fail: " + error);
    }
  );
}

export function GetAccount(userid, callback) {
  apiStudio.post("GetAccount", JSON.stringify({ userid: userid })).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetAccount fail: " + error);
    }
  );
}

export function UpdateUser(model, callback) {
  apiStudio.post("UpdateUser", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UpdateUser fail: " + error);
    }
  );
}

export function UpdateUserStatus(model, callback) {
  apiStudio.post("UpdateUserStatus", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UpdateUserStatus fail: " + error);
    }
  );
}

export function GetProjectSummary(model, callback) {
  apiStudio.post("getAccountSummary", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetProjectSummary fail: " + error);
    }
  );
}

export function GetShelfCategory(model, callback) {
  apiStudio.post("getShelfCategory", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetShelfCategory fail: " + error);
    }
  );
}

export function GetShelfMarket(model, callback) {
  apiStudio.post("getShelfMarket", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetShelfMarket fail: " + error);
    }
  );
}

export function GetDashboardDetails(model, callback) {
  apiStudio.post("Dashboarddetail", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetDashboardDetails fail: " + error);
    }
  );
}

// export function GetDashboardDetails(id, callback) {
//   apiStudio.post(`Dashboarddetail?userId=${id.userid}`, { data: null }).then(
//     (response) => {
//       if (callback) {
//         callback(response.data);
//       }
//     },
//     (error) => {
//       console.log("GetDashboardDetails fail: " + error);
//     }
//   );
// }

// export function GetDashboardDetails(id, callback) {
//   apiStudio
//     .get("DashboardDetail", {
//       params: {
//         userid: id.userid,
//       },
//       responseType: "json",
//     })
//     .then(
//       (response) => {
//         if (callback) {
//           callback(response.data);
//         }
//       },
//       (error) => {
//         console.log("GetCombineSurveyDetails fail: " + error);
//       }
//     );
// }

export function GetShelfLibrary(model, callback) {
  apiStudio.post("getShelfLibrary", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetShelfLibrary fail: " + error);
    }
  );
}

export function GetShelfLibraryV2(model, callback) {
  apiStudio.post("getShelfLibraryV2", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetShelfLibrary fail: " + error);
    }
  );
}

export function InsertSurveyPacktest(model, callback) {
  apiStudio.post("InsertSurveyPacktest", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("InsertSurveyPacktest fail: " + error);
    }
  );
}

export function UpdateSurveyPacktest(model, callback) {
  apiStudio.post("UpdateSurveyPacktest", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UpdateSurveyPacktest fail: " + error);
    }
  );
}

let apiShelfDetails = axios.create({
  baseURL: "https://apps.hotspex.com/extn/packspexws/pxservicefe.asmx/",
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
});

export function GetShelfDetails(shelfID, callback) {
  apiShelfDetails.post("GetShopspexSetting", JSON.stringify(shelfID)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetShopspexSetting fail: " + error);
    }
  );
}

export function GetShelfBrand(shelfImageId, callback) {
  apiStudio.post("getShelfBrand", JSON.stringify(shelfImageId)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetShelfBrand fail: " + error);
    }
  );
}

export function GetShelfBrandProducts(data, callback) {
  apiStudio.post("getShelfBrandImages", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetShelfBrandImages fail: " + error);
    }
  );
}

export function SavePackDetails(data, callback) {
  apiStudio.post("savePackDetails", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("SavePackDetails fail: " + error);
    }
  );
}
export function UpdatePackDetails(data, callback) {
  apiStudio.post("UpdatePackStimulusDetail", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UpdatePackDetails fail: " + error);
    }
  );
}

export function GetPackDetails(data, callback) {
  apiStudio.post("GetPackStimulusDetail", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetPackDetails fail: " + error);
    }
  );
}

export function GetSSOURl(data, callback) {
  apiStudio.post("getSSOURl", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetSSOURl fail: " + error);
    }
  );
}

export function AddAccount(data, callback) {
  apiStudio.post("AddClientManagement", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("AddAccount fail: " + error);
    }
  );
}

export function EditAccount(data, callback) {
  apiStudio.post("EditClientManagement", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("EditAccount fail: " + error);
    }
  );
}

export function DeleteAccount(data, callback) {
  apiStudio.post("DeleteClientManagement", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("DeleteAccount fail: " + error);
    }
  );
}

export function GetUserDropdown(data, callback) {
  apiStudio.post("GetUserDropdown", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetUserDropdown fail: " + error);
    }
  );
}

export function AddClientManagement(data, callback) {
  apiStudio.post("AddClientManagement", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("AddClientManagement fail: " + error);
    }
  );
}

export function GetAccountClients(data, callback) {
  apiStudio.post("GetAccountClient", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetAccountClient fail: " + error);
    }
  );
}

export function EditAccountClients(data, callback) {
  apiStudio.post("EditClientManagement", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("EditClientManagement fail: " + error);
    }
  );
}

export function DeleteAccountClients(data, callback) {
  apiStudio.post("DeleteClientManagement", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("DeleteClientManagement fail: " + error);
    }
  );
}

export function GetCommunicationObjectives(callback) {
  apiStudio.post("getCommunicationObjectives", { data: null }).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetCommunicationObjectives fail: " + error);
    }
  );
}

export function SavePackTestPersonaAttributes(data, callback) {
  apiStudio.post("SavePackTestPersonaAttributes", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("SavePackTestPersonaAttributes fail: " + error);
    }
  );
}

export function GetPgProject(data, callback) {
  apiStudio.post("getPgProject", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetPgProject fail: " + error);
    }
  );
}

export function GetCSVZoneData(data, callback) {
  apiStudio.post("getPackAreaDetail", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetCSVZoneData fail: " + error);
    }
  );
}

export function GetSurveyStatus(callback) {
  apiStudio.post("getSurveyStatus", { data: null }).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("getSurveyStatus fail: " + error);
    }
  );
}

export function UpdateSurveyStatus(data, callback) {
  apiStudio.post("updateStatus", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UpdateSurveyStatus fail: " + error);
    }
  );
}

export function GetThumbnailImage(data, callback) {
  apiStudio.post("GetThumbnailImage", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetThumbnailImage fail: " + error);
    }
  );
}

export function ConciergeRequest(data, callback) {
  apiStudio.post("ConciergeRequest", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("ConciergeRequest fail: " + error);
    }
  );
}

export function GetUserClients(data, callback) {
  apiStudio.post("GetUserClients", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetUserClients fail: " + error);
    }
  );
}

export function AddClient(data, callback) {
  apiStudio.post("SaveClient", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("AddClient fail: " + error);
    }
  );
}

export function DeleteClient(data, callback) {
  apiStudio.post("DeleteClient", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("DeleteClient fail: " + error);
    }
  );
}

export function GetCategoryStatements(data, callback) {
  apiStudio.post("getAllcatCogni", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetCategoryStatements fail: " + error);
    }
  );
}

export function PacktestHelpRequest(data, callback) {
  apiStudio.post("packtestHelpRequest", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("PacktestHelpRequest fail: " + error);
    }
  );
}

export function SaveSampleSize(data, callback) {
  apiStudio.post("setpacktestAudience", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("SaveSampleSize fail: " + error);
    }
  );
}

export function GetInfieldDetails(data, callback) {
  apiStudio.post("getInfieldDetails", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetInfieldDetails fail: " + error);
    }
  );
}

export function GetSurveyLog(data, callback) {
  apiStudio.post("GetSurveylog", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetSurveyLog fail: " + error);
    }
  );
}

export function GetSurveylogDetails(data, callback) {
  apiStudio.post("getSurveylogDetails", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetSurveylogDetails fail: " + error);
    }
  );
}

export function GetAccountFAQContent(data, callback) {
  apiStudio.post("getAccountFAQContent", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetAccountFAQContent fail: " + error);
    }
  );
}

export function GetGeneralErrorLog(data, callback) {
  apiStudio.post("getErrorLog", JSON.stringify(data)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetGeneralErrorLog fail: " + error);
    }
  );
}

export function GetSurveyReportRequirements(model, callback) {
  apiStudio.post("getSurveyReportRequirements", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetSurveyReportRequirements fail: " + error);
    }
  );
}

export function SaveSurveyReportRequirements(model, callback) {
  apiStudio.post("saveSurveyReportRequirements", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("SaveSurveyReportRequirements fail: " + error);
    }
  );
}

export function UploadpptFileAWS(model, callback) {
  apiStudio
    .post(
      `UploadpptFileAWS?surveyId=${model.surveyId}&userId=${model.userId}&overwrite=${model.overwrite}&notification=${model.notifications}&Recipients=${model.additionalRecipients}`,
      model.form
    )
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("UploadpptFileAWS fail: " + error);
      }
    );
}

export function UploadCustomReport(model, callback) {
  apiStudio
    .post(
      `UploadCustomReport?surveyId=${model.surveyId}&userId=${model.userId}&overwrite=${model.overwrite}&notification=${model.notifications}&Recipients=${model.additionalRecipients}`,
      model.form
    )
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("UploadCustomReport fail: " + error);
      }
    );
}

export function UploadRawFileAWS(model, callback) {
  apiStudio
    .post(
      `UploadRawFileAWS?surveyId=${model.surveyId}&userId=${model.userId}&overwrite=${model.overwrite}&notification=${model.notifications}&Recipients=${model.additionalRecipients}`,
      model.form
    )
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("UploadRawFileAWS fail: " + error);
      }
    );
}

export function UploadShelfFiles(model, callback) {
  apiStudio.post(`${model.wsName}`, model.form).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UploadRawFileAWS fail: " + error);
    }
  );
}

export function GetPriceInfo(model, callback) {
  apiStudio.post("getpriceinfotext", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetPriceInfo fail: " + error);
    }
  );
}

export function UpdatedUPCPriceList(model, callback) {
  apiStudio.post("UpdateCustomPackPrice", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UpdatedUPCPriceList fail: " + error);
    }
  );
}

export function UploadBrandLogo(model, callback) {
  apiStudio
    .post(`UploadBrandImage?surveyId=${model.surveyId}`, model.form)
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("UploadBrandLogo fail: " + error);
      }
    );
}

export function InsertSurveyStimReticle(stimObj, callback) {
  apiStudio.post("InsertSurveyStimReticle", JSON.stringify(stimObj)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("InsertSurveyStimReticle fail: " + error);
    }
  );
}

export function GetAdaptPackPrice(model, callback) {
  apiStudio.post("GetAdaptPackPrice", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetAdaptPackPrice fail: " + error);
    }
  );
}

export function UpdateReportOptions(model, callback) {
  apiStudio.post("UpdateReportOptions", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("UpdateReportOptions fail: " + error);
    }
  );
}

export function SetImplicitComponent(model, callback) {
  apiStudio.post("UpdateImplicitComponent", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("SetImplicitComponent fail: " + error);
    }
  );
}

export function GetSurveyStimReticle(surveyObj, callback) {
  apiStudio
    .get("GetSurveyStimReticle", {
      params: {
        SurveyID: surveyObj.SurveyID,
      },
      responseType: "json",
    })
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("GetSurveyStimReticle fail: " + error);
      }
    );
}

export function GetShelfRenderDetails(model, callback) {
  apiStudio.post("GetShelfRenderDetails", model.form).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetShelfRenderDetails fail: " + error);
    }
  );
}

export function UploadShelfBuilder(model, callback) {
  apiStudio
    .post(
      `${model.wsName}?surveyId=${model.surveyId}&shelfType=${
        model.wsName === "UploadBulkProductImage" ||
        model.wsName === "UploadProductsImages"
          ? model.shelfType
          : null
      }&upc=${model.wsName === "UploadProductsImages" ? model.upc : null}`,
      model.form
    )
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        if (callback) {
          callback({ success: false, error });
        }
      }
    );
}

export function UploadShelfImages(model, callback) {
  apiStudio.post(`${model.wsName}`, model.form).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      if (callback) {
        callback({ success: false, error });
      }
    }
  );
}

export function SaveShelfRenderDetails(model, callback) {
  apiStudio.post("SaveShelfRenderDetails", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("SaveShelfRenderDetails fail: " + error);
    }
  );
}

export function DownloadShelfRenderDetails(model, callback) {
  apiStudio
    .post("DownloadShelfRenderDetails", JSON.stringify(model), {
      responseType: "blob",
    })
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("DownloadShelfRenderDetails fail: " + error);
      }
    );
}

export function AddLeg(model, callback) {
  apiStudio.post("InsertSurveyPacktestParent", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("AddLeg fail: " + error);
    }
  );
}

export function GetShelfRenderData(model, callback) {
  apiStudio.post("GetShelfRenderData", JSON.stringify(model)).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log("GetShelfRenderData fail: " + error);
    }
  );
}

export function ShelfBuilderPost(model, callback) {
  const postData = model.data.form
    ? model.data.form
    : JSON.stringify(model.data);

  apiStudio.post(model.wsName, postData).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log(`${model.wsName} fail:` + error);
    }
  );
}

export function ExportPSAtoCSV(model, callback) {
  apiStudio
    .post("ExportPSAtoCSV", JSON.stringify(model), {
      responseType: "blob",
    })
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("ExportPSAtoCSV fail: " + error);
      }
    );
}

export function ApiPost(model, callback) {
  const postData = model.data.form
    ? model.data.form
    : JSON.stringify(model.data);

  apiStudio.post(model.wsName, postData).then(
    (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    (error) => {
      console.log(`${model.wsName} fail:` + error);
    }
  );
}

export function ApiGet(model, callback) {
  apiStudio
    .get(model.wsName, {
      params: model.params,
      responseType: model.responseType || "json",
    })
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log(`${model.wsName} fail: ` + error);
      }
    );
}

export function DownloadQuotasList(model, callback) {
  apiStudio
    .post("DownloadQuotasList", JSON.stringify(model), {
      responseType: "blob",
    })
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("Download Quotas Excel fail: " + error);
      }
    );
}

export function GetMapSurveyDetails(surveyObj, callback) {
  apiStudio
    .get("GetMapSurveyDetails", {
      params: {
        SurveyID: surveyObj.SurveyID,
        SurveyTypeID: surveyObj.SurveyTypeID,
      },
      responseType: "json",
    })
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("GetCombineSurveyDetails fail: " + error);
      }
    );
}

export function TabPlanProceed(data, callback) {
  apiStudio
    .post(`TabPlanProceed?surveyId=${data.surveyId}&userId=${data.userId}`)
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("TabPlanProceed fail: " + error);
      }
    );
}


export function ChatBot(model, callback) {
  const postData = model.data.form
    ? model.data.form
    : JSON.stringify(model.data);

  chatBotApi.post(model.wsName, postData).then(
    (response) => {
      if (response.status === 200) {
        // Proveravamo da li je status 200
        if (callback) {
          callback(null, response.data);  // Pozivamo callback sa podacima
        }
      } else {
        // Ako nije status 200, tretiraj kao grešku
        console.error(`${model.wsName} returned status: ${response.status}`);
        if (callback) {
          callback(`Error: Status code ${response.status}`, null);
        }
      }
    },
    (error) => {
      console.log(`${model.wsName} fail:` + error);
      if (callback) {
        callback(error, null);
      }
    }
  );
}
export function GetChatHistory(model, callback) {
  chatBotApi.post("getHistory", JSON.stringify(model)).then(
    (response) => {
      if (response.status === 200) {
        if (callback) {
          callback(null, response.data);  // Prosledi podatke kada je status 200
        }
      } else {
        console.error(`getHistory returned status: ${response.status}`);
        if (callback) {
          callback(`Error: Status code ${response.status}`, null);  // Obrađuje grešku za ne-200 status
        }
      }
    },
    (error) => {
      console.log("GetChatHistory fail: " + error);
      if (callback) {
        callback(error, null);  // Obrađuje greške u vezi sa API-jem
      }
    }
  );
}


export function GetConversation(model, callback) {
  chatBotApi.post("getConversation", JSON.stringify(model)).then(
    (response) => {
      if (response.status === 200) {
        if (callback) {
          callback(null, response.data);
        }
      } else {
        console.error(`getConversation returned status: ${response.status}`);
        if (callback) {
          callback(`Error: Status code ${response.status}`, null);
        }
      }
    },
    (error) => {
      console.log("GetConversation fail: " + error);
      if (callback) {
        callback(error, null);
      }
    }
  );
}


export function GetBrandFingerprintSurveyDetails(surveyObj, callback) {
  apiStudio
    .get("GetBrandFingerprintSurveyDetails", {
      params: {
        SurveyID: surveyObj.SurveyID,
        SurveyTypeID: surveyObj.SurveyTypeID,
      },
      responseType: "json",
    })
    .then(
      (response) => {
        if (callback) {
          callback(response.data);
        }
      },
      (error) => {
        console.log("GetBrandFingerprintSurveyDetails fail: " + error);
      }
    );
}