const config = {
  settingsNextRoute: "stimuli-custom",
  progressBarSteps: [
    {
      title: "General Settings",
      subItems: ["General Study information"],
      icon: {
        name: "IconAdjustments",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 1,
      route: "pack-test-custom-setting",
    },
    {
      title: "Stimuli",
      icon: {
        name: "IconChartHistogram",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 2,
      route: "stimuli-custom",
    },
    {
      title: "Brand Strategy",
      subItems: ["Emotional Attributes"],
      icon: {
        name: "IconBulb",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 3,
      route: "communication-objective-custom",
    },
    {
      id: 4,
      title: "Custom questions",
      subItems: ["Add custom questions"],
      icon: {
        name: "IconQuestion",
        width: "32",
        height: "33",
        viewBox1: "24",
        viewBox2: "24",
        color: "transparent",
      },
      index: 4,
      route: "custom-questions",
    },
    {
      title: "Launch",
      subItems: ["Summary & Confirmation"],
      icon: {
        name: "IconRocket",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 6,
      route: "pack-test-launch",
    },
  ],
};

export default config;
