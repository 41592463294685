import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import * as jsUtility from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import videoAdTestConfig from "./config/videoAdTest/config.js";
import videoAdYTTestConfig from "./config/videoAdYTTest/config.js";
import videoAdYTUATestConfig from "./config/videoAdYTUATest/config.js";
import videoAdUATestConfig from "./config/videoAdUATest/config.js";
import packTestCustomConfig from "./config/packTestCustom/config.js";
import videoAdTikTokTestConfig from "./config/videoAdTikTokTest/config.js";
import videoAdReticleTestConfig from "./config/videoAdReticleTest/config.js";
import videoAdTikTokUATestConfig from "./config/videoAdTikTokUATest/config.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Dashboard.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      const user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtility.readCookie("HS-STUDIO"));
      let url = window.location.origin
        .replace(/(^\w+:|^)\/\//, "")
        .replace(/\/+$/, "");
      const queryString = Object.fromEntries(
        new URLSearchParams(window.location.search)
      );

      const setEditMode = (data) => {
        let comObjects = 1;
        if (
          data.SurveyConfig.CommunicationObjective1 !== -1 &&
          data.SurveyConfig.CommunicationObjective1 !== 0 &&
          data.SurveyConfig.CommunicationObjective2 !== -1 &&
          data.SurveyConfig.CommunicationObjective2 !== 0
        ) {
          comObjects = 2;
        }

        let totalLegs = data.selectedProducts.filter(
          (e) => e.FriendlyName !== "Control" && !e.IsOldShelf
        );
        const surveyType = jsUtility.readCookie("survey-type");
        const extraLeg = surveyType === "packtestextend" ? 0 : 1;
        const setTotalLegs =
          totalLegs.length !== 0
            ? totalLegs.length /
                (data.selectedProducts.length - totalLegs.length) +
              extraLeg
            : 1;

        let packTestPriceSetData = {
          surveyId: data.SurveyConfig.SurveyID,
          explicitSamplePerDeepDive: data.audience.sampleSize,
          legs: setTotalLegs,
          deepDive:
            data.deepDiveProducts.length > 0 ? data.deepDiveProducts.length : 1,
          compDeepDive: data.competitvedeepDiveProducts.length,
          comObjects: comObjects,
          ReportOption: "Automated",
        };

        jsUtility.setPackTestPrice(packTestPriceSetData);
        jsUtility.setEditableSurvey(data);
        jsUtility.setRunSurveyMode(true);
      };

      const setSummaryData = (data) => {
        let surveySummary = {
          country: data.SurveyConfig.Country,
          categoryId: data.SurveyConfig.CategoryId,
          projectName: data.SurveyConfig.ProjectName,
          language: data.SurveyConfig.AlchemerLangName,
          category: data.SurveyConfig.CategoryName,
          brand: data.SurveyConfig.BrandName,
          ReportOption: "Automated",
        };
        jsUtility.setSurveySummaryData(surveySummary);
        jsUtility.setSurveyTypeId(data.SurveyConfig.SurveyTypeID);
      };

      const ssoClient =
        url.indexOf("localhost") === -1 &&
        url.indexOf("app.hotspexlabs.com") === -1 &&
        url.indexOf("staging-app.hotspexstudio.com") === -1 &&
        url.indexOf("app-uat.hotspexstudio.com") === -1 &&
        url.indexOf("app-staging.hotspexstudio.com") === -1 &&
        url.indexOf("dev-app.hotspexstudio.com") === -1;

      if (ssoClient && queryString.action === "projectPlanningTool") {
        next("/shelf-search");
      } else if (
        ssoClient &&
        queryString.action !== undefined &&
        queryString.pgProjectID !== undefined
      ) {
        let siteType = jsUtility.getSiteType();
        if (queryString.action === "packtestextend") {
          jsUtility.setCookie("survey-type", "packtestextend", 3);
        }

        const getPgProject = new Promise((resolve) => {
          wsUtils.GetPgProject(
            {
              PgProjectID: queryString.pgProjectID,
              userID: user.EncrypteduserID,
              domainType: siteType,
              ExternalParentProjectId: queryString.parentid,
            },
            resolve
          );
        });

        getPgProject.then((data) => {
          if (data.success) {
            if (
              data.message.SurveyConfig.SurveyName === "" ||
              data.message.SurveyConfig.BrandName === "" ||
              data.message.SurveyConfig.Country === "" ||
              data.message.SurveyConfig.AlchemerLangName === "" ||
              data.message.SurveyConfig.CategoryId === 0
            ) {
              setEditMode(data.message);
              setSummaryData(data.message);
              next({
                path: `/pack-test/${data.message.SurveyConfig.SurveyID}/setting`,
              });
            } else {
              setEditMode(data.message);
              setSummaryData(data.message);
              next({
                path: `/pack-test/${data.message.SurveyConfig.SurveyID}/stimuli`,
              });
            }
          } else {
            next({
              name: "global-error-page",
              params: {
                id: "WS1-0001",
                surveyId: queryString.pgProjectID,
                description: "Something went wrong while creating PG Project",
              },
            });
          }
        });
      } else if (
        user.dashboardURL !== "" &&
        user.dashboardURL !== undefined &&
        from.name !== "login"
      ) {
        window.open(user.dashboardURL, "_self", "noreferrer");
      } else {
        sessionStorage.removeItem("editableSurvey");
        next();
      }
    },
  },
  {
    path: "/all-projects",
    name: "all-projects",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Home.vue"),
    meta: {
      requireAuth: true,
      addPageScroll: true,
    },
  },
  {
    path: "/map-maker",
    name: "map-maker",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/MapMaker.vue"),
    meta: {
      requireAuth: true,
      addPageScroll: true,
    },
  },
  {
    path: "/shelf-library",
    name: "shelfLibrary",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/ShelfLibrary.vue"),
    meta: {
      requireAuth: true,
      addPageScroll: true,
    },
    beforeEnter: (to, from, next) => {
      const user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtility.readCookie("HS-STUDIO"));
      if (
        user.AccountType === "Agency" ||
        user.AccountType === "Enterprise" ||
        user.AccountType === "Master/Hotspex"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/shelf-search",
    name: "shelfSearch",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/ShelfSearch.vue"),
    meta: {
      requireAuth: true,
      addPageScroll: true,
    },
    beforeEnter: (to, from, next) => {
      const user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtility.readCookie("HS-STUDIO"));
      if (
        user.AccountType === "Agency" ||
        user.AccountType === "Enterprise" ||
        user.AccountType === "Master/Hotspex"
      ) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/support",
    name: "support",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Support.vue"),
    meta: {
      requireAuth: true,
      addPageScroll: true,
    },
  },
  {
    path: "/brand-operating-system",
    name: "brandOperatingSystem",
    component: () =>
      import(
        /* webpackChunkName: "products" */ "../views/BrandOperatingSystem.vue"
      ),
    meta: {
      requireAuth: true,
      addPageScroll: true,
    },
    beforeEnter: (to, from, next) => {
      const user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtility.readCookie("HS-STUDIO"));
      if (user.BOSModulesEnabled) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/shelf-details",
    name: "shelfDetails",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/ShelfApp.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Products.vue"),
    meta: {
      requireAuth: true,
      addPageScroll: true,
    },
  },
  {
    path: "/vid-test/:id/setting",
    name: "vid-setting",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/videoAds/VideoSetting.vue"
      ),
  },
  {
    path: "/comms-test/:id/setting",
    name: "comms-setting",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdYTTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/videoAds/VideoSetting.vue"
      ),
  },
  {
    path: "/vid-test-tiktok/:id/setting",
    name: "vid-tiktok-setting",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTikTokTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/videoAds/VideoSetting.vue"
      ),
  },
  {
    path: "/vid-test-reticle/:id/setting",
    name: "vid-reticle-setting",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdReticleTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/videoAds/VideoSetting.vue"
      ),
  },
  {
    path: "/vid-test-yt-ua/:id/setting",
    name: "vid-yt-ua-setting",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdYTUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/videoYTUASurvey/VideoSetting.vue"
      ),
  },
  {
    path: "/vid-test-tiktok-ua/:id/setting",
    name: "vid-tiktok-ua-setting",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTikTokUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/videoTikTokUASurvey/VideoSetting.vue"
      ),
  },
  {
    path: "/vid-test-ua/:id/setting",
    name: "vid-ua-setting",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/videoUASurvey/VideoSetting.vue"
      ),
  },
  {
    path: "/pack-test/:id/setting",
    name: "pack-test-setting",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/packTestSurvey/PackTestSettings.vue"
      ),
  },
  {
    path: "/pack-sbd-test/:id/setting",
    name: "pack-sbd-test-setting",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/packTestSurvey/PackTestSettings.vue"
      ),
  },
  {
    path: "/map-maker-test/:id/settings",
    name: "map-maker-settings",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/packTestSurvey/PackTestSettings.vue"
      ),
  },
  {
    path: "/pack-sbd-test/:id/category-inputs",
    name: "pack-sbd-category-inputs",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/packTestSurvey/CategoryInputs.vue"
      ),
  },
  {
    path: "/map-maker-test/:id/category-inputs",
    name: "map-maker-category-inputs",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/mapMaker/CategoryInputs.vue"
      ),
  },
  {
    path: "/pack-sbd-test/:id/quotas",
    name: "pack-quotas",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/packTestSurvey/Quotas.vue"
      ),
  },
  {
    path: "/pack-test-custom/:id/setting",
    name: "pack-test-custom-setting",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...packTestCustomConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/packTestSurvey/PackTestSettings.vue"
      ),
  },
  {
    path: "/pack-test/:id/stimuli",
    name: "stimuli",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        "../components/packTestSurvey/Stimuli.vue"
      ),
  },
  {
    path: "/pack-sbd-test/:id/stimuli",
    name: "stimuli-sbd",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        "../components/packTestSurvey/Stimuli.vue"
      ),
  },
  {
    path: "/map-maker-test/:id/stimuli",
    name: "stimuli-map-maker",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        "../components/mapMakerTest/Stimuli.vue"
      ),
  },
  {
    path: "/pack-test-custom/:id/stimuli",
    name: "stimuli-custom",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...packTestCustomConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-setting" */ "../components/packTestCustom/Stimuli.vue"
      ),
  },
  {
    path: "/pack-test/:id/communication-objective",
    name: "communication-objective",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "communication-objective" */ "../components/packTestSurvey/CommunicationObjective.vue"
      ),
  },
  {
    path: "/pack-sbd-test/:id/communication-objective",
    name: "communication-sbd-objective",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "communication-objective" */ "../components/packTestSurvey/CommunicationObjective.vue"
      ),
  },
  {
    path: "/pack-test-custom/:id/communication-objective",
    name: "communication-objective-custom",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...packTestCustomConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "communication-objective" */ "../components/packTestCustom/CommunicationObjective.vue"
      ),
  },
  {
    path: "/pack-test/:id/custom-questions",
    name: "custom-questions",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    beforeEnter: (to, from, next) => {
      const user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtility.readCookie("HS-STUDIO"));
      if (user.SkinIdentifier !== "pg") {
        next();
      } else {
        next("/");
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "custom-questions" */ "../components/packTestSurvey/CustomQuestions.vue"
      ),
  },
  {
    path: "/pack-test/:id/key-metrics",
    name: "pack-test-key-metrics",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      hidePrice: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "key-metric" */ "../components/videoAds/KeyMetrics.vue"
      ),
  },
  {
    path: "/pack-test/:id/audience",
    name: "pack-test-audience",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      hidePrice: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "audience" */ "../components/videoAds/Audience.vue"
      ),
  },
  {
    path: "/pack-test/:id/launch",
    name: "pack-test-launch",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-launch" */ "../components/packTestSurvey/PackTestSummary.vue"
      ),
  },
  {
    path: "/pack-sbd-test/:id/launch",
    name: "pack-sbd-test-launch",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-launch" */ "../components/packTestSurvey/PackTestSummary.vue"
      ),
  },
  {
    path: "/map-maker-test/:id/launch",
    name: "map-maker-test-launch",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-launch" */ "../components/packTestSurvey/PackTestSummary.vue"
      ),
  },
  {
    path: "/pack-test-custom/:id/launch",
    name: "pack-test-launch-custom",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...packTestCustomConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-launch" */ "../components/packTestCustom/PackTestSummary.vue"
      ),
  },
  {
    path: "/vid-test/:id/creative",
    name: "vid-creative",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/videoAds/CreativeUpload.vue"
      ),
  },
  {
    path: "/comms-test/:id/creative",
    name: "comms-creative",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdYTTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/videoAds/CreativeUpload.vue"
      ),
  },
  {
    path: "/vid-test-tiktok/:id/creative",
    name: "vid-tiktok-creative",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTikTokTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/videoAds/CreativeUpload.vue"
      ),
  },
  {
    path: "/vid-test-reticle/:id/creative",
    name: "vid-reticle-creative",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdReticleTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/videoAds/CreativeUpload.vue"
      ),
  },
  {
    path: "/vid-test-yt-ua/:id/creative",
    name: "vid-yt-ua-creative",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdYTUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/videoAds/CreativeUpload.vue"
      ),
  },
  {
    path: "/vid-test-tiktok-ua/:id/creative",
    name: "vid-tiktok-ua-creative",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTikTokUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/videoAds/CreativeUpload.vue"
      ),
  },
  {
    path: "/vid-test-ua/:id/creative",
    name: "vid-ua-creative",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/videoAds/CreativeUpload.vue"
      ),
  },
  {
    path: "/vid-test/:id/brand-strategy",
    name: "vid-brand-strategy",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
      ...videoAdTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "persona" */ "../components/videoAds/Persona.vue"
      ),
  },
  {
    path: "/comms-test/:id/brand-strategy",
    name: "comms-brand-strategy",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
      ...videoAdYTTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "persona" */ "../components/videoAds/Persona.vue"
      ),
  },
  {
    path: "/vid-test-tiktok/:id/brand-strategy",
    name: "vid-tiktok-brand-strategy",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
      ...videoAdTikTokTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "persona" */ "../components/videoAds/Persona.vue"
      ),
  },
  {
    path: "/vid-test-reticle/:id/brand-strategy",
    name: "vid-reticle-brand-strategy",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
      ...videoAdReticleTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "persona" */ "../components/videoAds/Persona.vue"
      ),
  },
  {
    path: "/vid-test-yt-ua/:id/brand-strategy",
    name: "vid-yt-ua-brand-strategy",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
      ...videoAdYTUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "persona" */ "../components/videoAds/Persona.vue"
      ),
  },
  {
    path: "/vid-test-tiktok-ua/:id/brand-strategy",
    name: "vid-tiktok-ua-brand-strategy",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
      ...videoAdTikTokUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "persona" */ "../components/videoAds/Persona.vue"
      ),
  },
  {
    path: "/vid-test-ua/:id/brand-strategy",
    name: "vid-ua-brand-strategy",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
      ...videoAdUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "persona" */ "../components/videoAds/Persona.vue"
      ),
  },
  {
    path: "/vid-test/:id/key-metrics",
    name: "vid-key-metrics",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "key-metric" */ "../components/videoAds/KeyMetrics.vue"
      ),
  },
  {
    path: "/vid-test-ua/:id/customize",
    name: "vid-ua-customize",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "key-metric" */ "../components/videoUASurvey/Customize.vue"
      ),
  },
  {
    path: "/comms-test/:id/key-metrics",
    name: "comms-key-metrics",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdYTTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "key-metric" */ "../components/videoAds/KeyMetrics.vue"
      ),
  },
  {
    path: "/vid-test-tiktok/:id/key-metrics",
    name: "vid-tiktok-key-metrics",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTikTokTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "key-metric" */ "../components/videoAds/KeyMetrics.vue"
      ),
  },
  {
    path: "/vid-test-reticle/:id/key-metrics",
    name: "vid-reticle-key-metrics",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdReticleTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "key-metric" */ "../components/videoAds/KeyMetrics.vue"
      ),
  },
  {
    path: "/vid-test-yt-ua/:id/key-metrics",
    name: "vid-yt-ua-key-metrics",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdYTUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "key-metric" */ "../components/videoYTUASurvey/KeyMetrics.vue"
      ),
  },
  {
    path: "/vid-test-tiktok-ua/:id/key-metrics",
    name: "vid-tiktok-ua-key-metrics",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTikTokUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "key-metric" */ "../components/videoTikTokUASurvey/KeyMetrics.vue"
      ),
  },
  {
    path: "/vid-test/:id/audience",
    name: "vid-audience",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "audience" */ "../components/videoAds/Audience.vue"
      ),
  },
  {
    path: "/comms-test/:id/audience",
    name: "comms-audience",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdYTTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "audience" */ "../components/videoAds/Audience.vue"
      ),
  },
  {
    path: "/vid-test-tiktok/:id/audience",
    name: "vid-tiktok-audience",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTikTokTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "audience" */ "../components/videoAds/Audience.vue"
      ),
  },
  {
    path: "/vid-test-reticle/:id/audience",
    name: "vid-reticle-audience",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdReticleTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "audience" */ "../components/videoAds/Audience.vue"
      ),
  },
  {
    path: "/vid-test-ua/:id/audience",
    name: "vid-ua-audience",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "audience" */ "../components/videoAds/Audience.vue"
      ),
  },
  {
    path: "/vid-test/:id/launch",
    name: "vid-launch",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-launch" */ "../components/videoAds/Summary.vue"
      ),
  },
  {
    path: "/comms-test/:id/launch",
    name: "comms-launch",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdYTTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-launch" */ "../components/videoAds/Summary.vue"
      ),
  },
  {
    path: "/vid-test-tiktok/:id/launch",
    name: "vid-tiktok-launch",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTikTokTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-launch" */ "../components/videoAds/Summary.vue"
      ),
  },
  {
    path: "/vid-test-reticle/:id/launch",
    name: "vid-reticle-launch",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdReticleTestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-launch" */ "../components/videoAds/Summary.vue"
      ),
  },
  {
    path: "/vid-test-yt-ua/:id/launch",
    name: "vid-yt-ua-launch",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdYTUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-launch" */ "../components/videoAds/Summary.vue"
      ),
  },
  {
    path: "/vid-test-tiktok-ua/:id/launch",
    name: "vid-tiktok-ua-launch",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdTikTokUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-launch" */ "../components/videoAds/Summary.vue"
      ),
  },
  {
    path: "/vid-test-ua/:id/launch",
    name: "vid-ua-launch",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      ...videoAdUATestConfig,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-launch" */ "../components/videoAds/Summary.vue"
      ),
  },
  {
    path: "/update-profile",
    name: "update-profile",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
    },
    beforeEnter: (to, from, next) => {
      const user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtility.readCookie("HS-STUDIO"));
      if (user.EmailId.includes("hotspex.com")) {
        next("/");
      } else {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/general/UpdateProfile.vue"
      ),
  },
  {
    path: "/user-management",
    name: "user-management",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
    },
    beforeEnter: (to, from, next) => {
      const user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtility.readCookie("HS-STUDIO"));
      //1 Basic, 2 Enterprise, 3 Agency
      if (user.planID === 2 || user.planID === 3 || user.planID === 4) {
        next();
      } else {
        next("/");
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/general/UserManagement.vue"
      ),
  },
  {
    path: "/account-management",
    name: "account-management",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
    },
    beforeEnter: (to, from, next) => {
      const user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtility.readCookie("HS-STUDIO"));
      //1 Basic, 2 Enterprise, 3 Agency
      if (user.planID === 2 || user.planID === 3) {
        next();
      } else {
        next("/");
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/general/AccountManagement.vue"
      ),
  },
  {
    path: "/client-management",
    name: "client-management",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
    },
    beforeEnter: (to, from, next) => {
      const user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtility.readCookie("HS-STUDIO"));
      //1 Basic, 2 Enterprise, 3 Agency
      if (user.planID === 3) {
        next();
      } else {
        next("/");
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/general/ClientManagement.vue"
      ),
  },
  {
    path: "/create-user",
    name: "create-user",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/general/User.vue"
      ),
  },
  {
    path: "/edit-user",
    name: "edit-user",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../components/general/User.vue"
      ),
  },
  {
    path: "/define-report/:id",
    name: "define-report",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      addPageScroll: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "vid-creative" */ "../views/DefineReport.vue"
      ),
  },
  {
    path: "/PaymentConfirmation",
    name: "payment-confirmation",
    meta: {
      requireAuth: true,
      requireVidLang: true,
      hideNavigation: true,
      addPageScroll: true,
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "payment-confirmation" */ "../views/PaymentConfirmation.vue"
      ),
  },
  {
    path: "/shelf-builder/:id/:projectName/:cipId",
    name: "shelf-builder",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/ShelfBuilder.vue"),
    meta: {
      requireAuth: true,
      addPageScroll: true,
    },
  },
  {
    path: "/shelf-builder-library",
    name: "shelf-builder-library",
    component: () =>
      import(
        /* webpackChunkName: "products" */ "../views/ShelfBuilderLibrary.vue"
      ),
    meta: {
      requireAuth: true,
      addPageScroll: true,
    },
  },
  {
    path: "/ErrorPage",
    name: "error-page",
    meta: {
      requireAuth: true,
      requireVidLang: true,
      hideNavigation: true,
      addPageScroll: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment-confirmation" */ "../views/ErrorPage.vue"
      ),
  },
  {
    path: "/error/:id",
    name: "global-error-page",
    props: true,
    meta: {
      requireAuth: false,
      requireVidLang: true,
      hideNavigation: true,
      addPageScroll: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment-confirmation" */ "../views/GlobalErrorPage.vue"
      ),
  },
  {
    path: "/pack-test/:id/launch-details",
    name: "launch",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
      hideNavigation: true,
      addPageScroll: true,
      hidePrice: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment-confirmation" */ "../views/LaunchInfo.vue"
      ),
  },
  // {
  //   path: "/launch-details-pdf",
  //   name: "launch",
  //   props: true,
  //   meta: {
  //     requireAuth: true,
  //     requireVidLang: true,
  //     hideNavigation: true,
  //     addPageScroll: true,
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "payment-confirmation" */ "../views/LaunchInfoPDF.vue"
  //     ),
  // },
  {
    path: "/samlssos-singout",
    name: "samlssos-singout",
    meta: {
      requireAuth: true,
      requireVidLang: true,
      hideNavigation: true,
      addPageScroll: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment-confirmation" */ "../views/SamlssosSingout.vue"
      ),
  },
  {
    path: "/map-explorer",
    name: "map-explorer",
    props: true,
    meta: {
      requireAuth: false,
      requireVidLang: false,
      hideNavigation: true,
      addPageScroll: true,
      smallHeader: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment-confirmation" */ "../views/MapExplorer.vue"
      ),
  },
  {
    path: "/clickspex-report",
    name: "clickspex-report",
    props: true,
    meta: {
      requireAuth: false,
      requireVidLang: false,
      hideNavigation: true,
      addPageScroll: true,
      smallHeader: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "payment-confirmation" */ "../views/ClickspexReport.vue"
      ),
  },
  {
    path: "/pack-test-custom/:id/custom-questions",
    name: "pack-custom-questions",
    props: true,
    meta: {
      requireAuth: true,
      requireVidLang: true,
    },
    beforeEnter: (to, from, next) => {
      const user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtility.readCookie("HS-STUDIO"));
      if (user.SkinIdentifier !== "pg") {
        next();
      } else {
        next("/");
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "custom-questions" */ "../components/packTestSurvey/CustomQuestions.vue"
      ),
  },
  {
    path: "/study-results/:id",
    name: "study-results",
    props: true,
    meta: {
      requireAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "custom-questions" */ "../components/packTestSurvey/StudyResults.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      hideNavigation: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    meta: {
      hideNavigation: true,
    },
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Signup.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: {
      hideNavigation: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"
      ),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: {
      hideNavigation: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/ForgotPassword.vue"
      ),
  },
  {
    path: "/email-confirm",
    name: "email-confirm",
    meta: {
      hideNavigation: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "emial-confirm" */ "../views/EmailConfirm.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)*",
    beforeEnter: (to, from, next) => {
      next({
        name: "login",
      });
    },
    // component: () =>
    //   import(/* webpackChunkName: "emial-confirm" */ "../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: "/app",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {


  let referrerSiteUrl = document.referrer
    .replace(/(^\w+:|^)\/\//, "")
    .replace(/\/+$/, "");

  let siteType = "PROD";

  if (referrerSiteUrl.indexOf("d3n7xxuq0fwznj.cloudfront.net") !== -1) {
    siteType = "DEV";
  } else if (referrerSiteUrl.indexOf("d30wz03irsnsp8.cloudfront.net") !== -1) {
    siteType = "QA";
  }
  localStorage.setItem("siteType", siteType);

  const checkSSOLogin = (routeName) => {
    let url = window.location.origin
      .replace(/(^\w+:|^)\/\//, "")
      .replace(/\/+$/, "");
    const queryString = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );
    const ssoClient =
      url.indexOf("localhost") === -1 &&
      url.indexOf("app.hotspexlabs.com") === -1 &&
      url.indexOf("staging-app.hotspexstudio.com") === -1 &&
      url.indexOf("app-uat.hotspexstudio.com") === -1 &&
      url.indexOf("app-staging.hotspexstudio.com") === -1 &&
      url.indexOf("dev-app.hotspexstudio.com") === -1 &&
      to.name !== "global-error-page";

    if (ssoClient) {
      let model = {
        labsURL: url,
        action: queryString.action || "",
        pgprojectid: queryString.pgProjectID || "",
      };
      const getSSOURl = new Promise((resolve) => {
        wsUtils.GetSSOURl(model, resolve);
      });
      let cookieData = {
        action: queryString.action || "",
        pgprojectid: queryString.pgProjectID || "",
      };
      getSSOURl.then((data) => {
        jsUtility.setCookie("sso-login", JSON.stringify(cookieData), 3);
        window.open(data, "_self", "noreferrer");
      });
    } else {
      jsUtility.deleteCookie("SAMLSSOSignedIn");
      if (routeName) {
        next({
          name: routeName,
        });
      } else {
        next();
      }
    }
  };

  const rawStudioUser = localStorage.getItem("studioUser");
  const rawCookieUser = jsUtility.readCookie("HS-STUDIO");
  const user =
    (rawStudioUser && JSON.parse(rawStudioUser)) ||
    (rawCookieUser && JSON.parse(rawCookieUser));

  const isPackTestRoute = to.path.startsWith("/pack-test");
  if (
    user &&
    user.UserType === "Guest" &&
    !isPackTestRoute &&
    to.meta.requireAuth
  ) {
    jsUtility.clearEditableSurvey();
    next("/login");
    return;
  }

  if (to.meta.requireAuth) {
    localStorage.setItem("toRouterPath", to.path);
    let account = jsUtility.readCookie("HS-STUDIO");
    if (account) {
      if (to.meta.requireVidLang) {
        axios.get("/tooltip/videoAuthor.json", {}).then((response) => {
          localStorage.setItem(
            "videoAuthorLang",
            JSON.stringify(response.data)
          );
          next();
        });
      } else {
        next();
      }
    } else {
      checkSSOLogin("login");
    }
  } else {
    if (to.meta.requireVidLang) {
      axios.get("/tooltip/videoAuthor.json", {}).then((response) => {
        localStorage.setItem("videoAuthorLang", JSON.stringify(response.data));
        checkSSOLogin();
      });
    } else {
      checkSSOLogin();
    }
  }
});

export default router;
